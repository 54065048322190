import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/checkout/checkout";
import Services from "../../Services/Services";

export default class Checkout extends Component {
  template = templateDefault;

  group = "order";

  default_address = {
    first_name: "",
    last_name: "",
    street: "",
    city: "",
    postal_code: "",
    phone: "",
    state: "",
    country: "",
    notes: "",
  };

  onAuth(data) {
    this.setUser();
  }

  onLoad(data) {
    super.onLoad(data);
    this.setUser();

    Services.get("order").then(([orderService]) => {
      orderService
        .fetchOrder()
        .then((orderService) => {
          const order = orderService.getData("order");
          this.setData({
            "default.expanded": order.user ? "step2" : "step1",
          });

          if (order.user) {
            this.initGoogleAutocomplete("shipping_address");
          }

          this.setOrder(order);
        })
        .catch((orderService) => {
          this.checkOrderError(orderService);
        });

      orderService
        .fetchPaymentTypes()
        .then((orderService) => {
          const paymentTypes = orderService.getData("paymentTypes");
          this.setData({ "default.paymentTypes": paymentTypes });
        })
        .catch((err) => {
          console.log("error", err);
        });
    });
  }

  setUser() {
    const isAuth = this.isAuthenticated();
    if (isAuth) {
      this.retrieveAddresses();
      this.retrieveCoupons();
    }

    this.setData({
      "default.guest": !isAuth,
      "default.username": isAuth
        ? this.getHelpers("auth").getUserAttribute("name")
        : "guest",
    });
  }

  setOrder(order) {
    if (
      order.shipping_address === null ||
      Array.isArray(order.shipping_address)
    ) {
      order.shipping_address = { ...this.default_address };
    }
    if (
      order.billing_address === null ||
      Array.isArray(order.billing_address)
    ) {
      order.billing_address = { ...this.default_address };
    }

    this.createPaypal(order);

    this.setData({
      "default.order": order,
    });

    return this;
  }

  screenScrollTo(id) {
    setTimeout(() => {
      window.scrollTo({
        top: document.getElementById(id).offsetTop - 40,
        left: 0,
        behavior: "smooth",
      });
    }, 500);
  }

  login(e) {
    e.preventDefault();

    const error = {};
    const guest = this.getData("default.guest");
    const { username, password = "" } = this.getData("default.fields.auth", {});

    if (!username) {
      error["username"] = this.ucfirst("email-helpertext");
    } else {
      if (!this.getHelpers("validate").email(username, {})) {
        error["username"] = this.ucfirst("invalid-email");
      }
    }

    if (!guest) {
      if (!password) {
        error["password"] = this.ucfirst("password-helpertext");
      }
    }

    this.setData({
      "error.fields.auth": error,
    });

    if (Object.keys(error).length === 0) {
      if (guest) {
        Services.get("order").then(([orderService]) => {
          orderService
            .addGuest({ guest: username })
            .then((orderService) => {
              const order = orderService.getData("order");
              this.setOrder(order);
              this.setData({ "default.expanded": "step2" });
              this.initGoogleAutocomplete("shipping_address");
              this.screenScrollTo("step2");
            })
            .catch((orderService) => {
              this.checkOrderError(orderService);
            });
        });
      } else {
        Services.get("auth,order").then(([authService, orderService]) => {
          authService
            .login({ username, password })
            .then((authService) => {
              this.getHelpers("Auth").set(
                authService.getData("_response").getData()
              );
              this.getPage().login();

              return orderService.fetchOrder();
            })
            .then((orderService) => {
              const order = orderService.getData("order");
              this.setOrder(order);
              this.setData({
                "default.expanded": "step2",
              });
              this.screenScrollTo("step2");
            })
            .catch((authService) => {
              this.getComponents()
                .findById("main-message")
                .first()
                .setData({
                  "error-message": authService
                    .getData("_response")
                    .getMessage(),
                });
            });
        });
      }
    } else {
      this.screenScrollTo("step1");
    }
  }

  initGoogleAutocomplete(type) {
    setTimeout(() => {
      const inputElement = document.getElementById(type + "_autocomplete");
      if (!inputElement) return;

      const autocomplete = new window.google.maps.places.Autocomplete(
        inputElement,
        {
          types: ["address"],
          componentRestrictions: { country: "US" },
          fields: ["address_components", "geometry"],
        }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) return;

        const addressComponents = this.parseAddressComponents(
          place.address_components
        );
        const {
          street,
          city,
          state,
          country,
          postal_code: postalCode,
          street_number: streetNumber,
        } = addressComponents;

        const address = this.getData("default.order." + type, {
          ...this.default_address,
        });

        this.deleteData("error");

        // Build the address object
        address.street = `${streetNumber} ${street}`.trim();
        address.street_number = streetNumber;
        address.city = city || "";
        address.postal_code = postalCode || "";
        address.state = state || "";
        address.country = country || "";
        address.typed_text = "";

        // Validate the address
        const error = this.getData("error", {});

        error[type] = this.getHelpers("validate").validate(address, {
          street: [
            {
              rule: "required",
              message: this.ucfirst("street-helpertext"),
            },
          ],
          street_number: [
            {
              rule: "required",
              message: this.ucfirst("street_number-helpertext"),
            },
          ],
          city: [
            {
              rule: "required",
              message: this.ucfirst("city-helpertext"),
            },
          ],
          postal_code: [
            {
              rule: "required",
              message: this.ucfirst("postal_code-helpertext"),
            },
          ],
          state: [
            {
              rule: "required",
              message: this.ucfirst("phone-helpertext"),
            },
          ],
          country: [
            {
              rule: "required",
              message: this.ucfirst("country-helpertext"),
            },
          ],
        });

        // If valid, update the address in the state
        this.setData({
          ["default.order." + type]: address,
          error,
        });
      });
    }, 500);
  }

  // Helper function to parse address components
  parseAddressComponents(components) {
    const result = {
      street: "",
      street_number: "",
      city: "",
      state: "",
      country: "",
      postal_code: "",
    };

    components.forEach((component) => {
      const type = component.types[0];
      switch (type) {
        case "street_number":
          result.street_number = component.long_name;
          break;
        case "route":
          result.street = component.short_name;
          break;
        case "postal_code":
          result.postal_code = component.long_name;
          break;
        case "postal_code_suffix":
          result.postal_code += `-${component.long_name}`;
          break;
        case "locality":
          result.city = component.short_name;
          break;
        case "sublocality_level_1":
          result.city = result.city || component.short_name;
          break;
        case "administrative_area_level_1":
          result.state = component.long_name;
          break;
        case "country":
          result.country = component.short_name;
          break;
        default:
          break;
      }
    });

    return result;
  }

  addAddress(e) {
    e.preventDefault();

    this.setData({
      error: { shipping_address: {}, billing_address: {} },
    });
    const { guest, addresses = [] } = this.getData("default");
    var { shipping_address = null, billing_address = null } =
      this.getData("default.order");
    var shippingAddressId = null;
    var billingAddressId = null;
    if (addresses.length === 0) {
      shipping_address = this.validateAddress(
        shipping_address,
        "shipping_address"
      );
      if (document.getElementById("billing_address_title")) {
        billing_address = this.validateAddress(
          billing_address,
          "billing_address"
        );
      } else {
        billing_address = shipping_address;
      }
    } else {
      shippingAddressId = this.findAddressSelected("shipping");

      if (document.getElementById("billing_address_title")) {
        billingAddressId = this.findAddressSelected("billing");
      } else {
        billingAddressId = shippingAddressId;
      }
    }

    const error = this.getData("error", {});

    if (
      !Object.keys(error.shipping_address).length > 0 &&
      !Object.keys(error.billing_address).length > 0
    ) {
      shipping_address = this.formatAddress(shipping_address);
      billing_address = this.formatAddress(billing_address);

      if (guest) {
        this.setData({
          "default.shipping_address": shipping_address,
          "default.billing_address": billing_address,
          "default.expanded": "step3",
        });

        this.updateAddress();
        this.screenScrollTo("step3");
      } else {
        if (addresses.length === 0) {
          Services.get("address").then(([addressService]) => {
            addressService
              .createAddress(shipping_address)
              .then((addressService) => {
                return addressService.getAddresses();
              })
              .then((addressService) => {
                const shippingAddressId = addressService
                  .getData("addresses")
                  .reverse()[0].id;
                const addresses = addressService.getData("addresses");
                this.setData({
                  "default.addresses": addresses,
                  "default.shippingAddressId": shippingAddressId,
                  "default.billingAddressId": shippingAddressId,
                  "default.expanded": "step3",
                });
                this.updateAddress({
                  shippingAddressId,
                  billingAddressId: shippingAddressId,
                });
              })
              .catch((err) => {
                console.log("error", err);
              });
          });
          if (document.getElementById("billing_address_title")) {
            Services.get("address").then(([addressService]) => {
              addressService
                .createAddress(billing_address)
                .then((addressService) => {
                  return addressService.getAddresses();
                })
                .then((addressService) => {
                  const billingAddressId = addressService
                    .getData("addresses")
                    .reverse()[0].id;
                  const addresses = addressService.getData("addresses");
                  this.setData({
                    "default.addresses": addresses,
                    "default.billingAddressId": billingAddressId,
                    "default.expanded": "step3",
                  });
                  this.updateAddress({
                    billingAddressId: shippingAddressId,
                  });
                })
                .catch((err) => {
                  console.log("error", err);
                });
            });
          }
        } else {
          this.setData({
            "default.shippingAddressId": shippingAddressId,
            "default.billingAddressId": billingAddressId,
            "default.expanded": "step3",
          });
          this.updateAddress();
        }
        this.screenScrollTo("step3");
      }
    } else {
      this.screenScrollTo("step2");
    }
  }

  formatAddress(address) {
    address.address = `${address.street} ${address.city},${address.state} ${address.postal_code}, ${address.country}`;

    return address;
  }

  validateAddress(address, type) {
    const error = this.getData("error", {});

    error[type] =
      this.getHelpers("validate").validate(address, {
        first_name: [
          {
            rule: "required",
            message: this.ucfirst("first_name-helpertext"),
          },
        ],
        last_name: [
          {
            rule: "required",
            message: this.ucfirst("last_name-helpertext"),
          },
        ],
        phone: [
          {
            rule: "required",
            message: this.ucfirst("phone-helpertext"),
          },
        ],
        street: [
          {
            rule: "required",
            message: this.ucfirst("street_address-helpertext"),
          },
        ],
        city: [
          {
            rule: "required",
            message: this.ucfirst("city-helpertext"),
          },
        ],
        postal_code: [
          {
            rule: "required",
            message: this.ucfirst("postal_code-helpertext"),
          },
        ],
        state: [
          {
            rule: "required",
            message: this.ucfirst("state-helpertext"),
          },
        ],
      }) || {};

    this.setData({
      error,
    });

    return address;
  }

  findAddressSelected(type) {
    var id = 0;
    var orders_address =
      this.getData(`default.order.${type}_address`, {}) || {};
    const addresses = this.getData("default.addresses", []) || [];
    if (orders_address) {
      if (orders_address._id) {
        addresses.forEach((address) => {
          if (address.id === orders_address._id) {
            id = orders_address._id;
          }
        });
      }
      if (id === 0) {
        id = addresses[0].id;
      }
    }
    return id;
  }

  retrieveAddresses() {
    Services.get("address").then(([addressService]) => {
      addressService
        .getAddresses()
        .then((addressService) => {
          const addresses = addressService.getData("addresses", null);
          this.setData({
            "default.addresses": addresses,
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    });
  }

  selectAddress(addressId, type) {
    this.setData({ [`default.${type}AddressId`]: addressId });
    this.updateAddress();
  }

  updateAddress(data) {
    const {
      shipping_address = {},
      billing_address = {},
      shippingAddressId = null,
      billingAddressId = null,
    } = data || this.getData("default", {});

    Services.get("order").then(async ([orderService]) => {
      orderService
        .updateAddress({
          shippingAddress: shipping_address,
          billingAddress: billing_address,
          shippingAddressId,
          billingAddressId,
        })
        .then((orderService) => {
          const order = orderService.getData("order");
          this.setOrder(order);
        })
        .catch((orderService) => {
          this.checkOrderError(orderService);
        });
    });

    return this;
  }

  setAddressId(id) {
    this.getComponents()
      .findById("address-dialog")
      .forEach((comp) => {
        comp.selectAddress(id);
      });
  }

  activateAutocomplete() {
    this.getComponents()
      .findById("address-dialog")
      .forEach((comp) => {
        comp.initGoogleAutocomplete();
      });
  }

  findServiceSelected() {
    const { services = [] } = this.getData("default.order", {}) || {};
    var selectedService = services.find((service) => service.selected);
    return selectedService.id;
  }

  updateService(id) {
    const { order } = this.getData("default", {}) || {};

    if (order) {
      Services.get("order").then(async ([orderService]) => {
        orderService
          .updateService({
            serviceId: id,
          })
          .then((orderService) => {
            const order = orderService.getData("order");
            this.setData({ "default.order": order });
            this.createPaypal(order);
          })
          .catch((orderService) => {
            this.checkOrderError(orderService);
          });
      });
    }
  }

  /* coupons section */

  retrieveCoupons() {
    Services.get("coupon").then(([couponService]) => {
      couponService
        .getCoupons()
        .then((couponService) => {
          const coupons = couponService.getData("coupons", []);

          this.setData({
            "default.coupons": coupons,
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    });
  }

  activateCoupon(couponId) {
    Services.get("order").then(([orderService]) => {
      orderService
        .updateCoupon({ couponId })
        .then((orderService) => {
          const order = orderService.getData("order");
          this.retrieveCoupons();
          this.setData({
            "default.couponCode": "",
          });
          this.setOrder(order);
        })
        .catch((err) => {
          console.log("error", err);
        });
    });
  }

  updateCouponByCode(e) {
    e.preventDefault();

    const { guest, coupons = [] } = this.getData("default");

    var error = {};

    let { couponCode = "" } = this.getData("default");

    if (couponCode === "") {
      error.couponCode = this.ucfirst("promo-code-helpertext");
    } else {
      couponCode = couponCode.toLowerCase();
    }

    this.setData({
      error,
    });
    if (Object.keys(error).length === 0) {
      if (guest) {
        Services.get("order").then(async ([orderService]) => {
          orderService
            .updateCoupon({
              couponCode,
            })
            .then((orderService) => {
              const order = orderService.getData("order");
              this.setData({
                "default.couponCode": "",
              });
              this.setOrder(order);
            })
            .catch((orderService) => {
              this.setData({
                "error.couponCode": this.ucfirst(orderService.getMessage()),
              });
            });
        });
      } else {
        const existingCoupon = coupons.find(
          (coupon) => coupon.slug === couponCode
        );
        if (!existingCoupon) {
          Services.get("order,coupon").then(([orderService, couponService]) => {
            couponService
              .addCoupon(couponCode)
              .then((couponService) => {
                const couponId = couponService.getData("coupon");
                this.retrieveCoupons();
                return orderService.updateCoupon({
                  couponId,
                });
              })
              .then((orderService) => {
                const order = orderService.getData("order");
                this.setData({
                  "default.couponCode": "",
                });
                this.setOrder(order);
                this.updateCart();
              })
              .catch((err) => {
                if (
                  couponService.getData("_response").getMessage() === "error"
                ) {
                  this.getComponents()
                    .findById("main-message")
                    .first()
                    .setData({
                      message: {
                        text: this.trans("coupon-unavailable-message"),
                        type: "error",
                      },
                    });
                } else {
                  console.log("error", err);
                }
              });
          });
        } else {
          this.activateCoupon(existingCoupon._id);
        }
      }
    }

    return this;
  }

  releaseCoupon() {
    Services.get("order").then(async ([orderService]) => {
      orderService
        .releaseCoupon()
        .then((orderService) => {
          const order = orderService.getData("order");
          if (this.isAuthenticated()) {
            this.retrieveCoupons();
          }
          this.setData({ "default.order": order });
          this.createPaypal(order);
        })
        .catch((orderService) => {
          //
        });
    });
  }

  /* payment types section */

  updatePaymentType(id) {
    const { order } = this.getData("default", {}) || {};
    if (order) {
      Services.get("order").then(async ([orderService]) => {
        orderService
          .updatePaymentType({
            paymentTypeId: id,
          })
          .then((orderService) => {
            const order = orderService.getData("order");
            this.setData({ "default.order": order });
            this.createPaypal(order);
          })
          .catch((orderService) => {
            this.checkOrderError(orderService);
          });
      });
    }
  }

  findPaymentTypeSelected(type) {
    var id = 0;
    var paymentType = this.getData("default.order.paymentType", {}) || {};
    if (paymentType) {
      id = paymentType.id;
    }
    return id;
  }

  complete(e) {
    e.preventDefault();

    Services.get("order").then(async ([orderService]) => {
      try {
        await orderService.prepare();
        await orderService.place();
        this.getPage().redirectToRedirect("/");
        this.getComponents()
          .findById("main-message")
          .first()
          .setData({
            "message-duration": 5000,
            "success-message": `${this.trans(
              "complete-order-title"
            )} ${this.trans("complete-order-subtitle")}`,
          });
      } catch (orderService) {
        this.checkOrderError(orderService);
      }
    });
  }

  checkOrderError(service) {
    if (service && service.getMessage && service.getError) {
      const type = service.getMessage() || service.getError();

      switch (type) {
        case "order-missing":
        case "order-expired":
        case "order-placed": {
          this.getComponents()
            .findById("main-message")
            .first()
            .setData({
              "error-message": this.trans(type),
            });
          this.getPage().redirectTo("/");
          break;
        }
        default:
          break;
      }
    }

    return this;
  }

  async createPaypal(order) {
    if (!order) return;

    const { paymentType, totals } = order;
    const grandTotal = totals.grand;
    const isPaypal = paymentType.slug === "paypal";
    const paypalContainer = document.getElementById("paypal-container");

    if (paypalContainer) {
      paypalContainer.innerHTML = isPaypal
        ? '<div id="paypal-button-container"></div>'
        : "";
    }

    if (!isPaypal || !paypalContainer) return;

    await this.addPayPalScript();

    if (window.paypal) {
      window.paypal
        .Buttons({
          // onInit: function (data, actions) {
          //   //
          // },
          // onCancel: function (data) {
          //   //
          // },
          onClick: function (data, actions) {
            return Services.get("order")
              .then(([orderService]) => {
                return orderService.prepare();
              })
              .then((orderService) => {
                const order = orderService.getData("order");
                return order && order.ready
                  ? actions.resolve()
                  : actions.reject();
              })
              .catch((orderService) => {
                return actions.reject();
              });
          },
          // Sets up the transaction when a payment button is clicked
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: grandTotal,
                  },
                  noshipping: true,
                },
              ],
              application_context: {
                shipping_preference: "NO_SHIPPING",
                billing_preference: "NO_BILLING",
              },
            });
          },
          // Finalize the transaction after payer approval
          onApprove: (data, actions) => {
            return actions.order.capture().then((orderData) => {
              const transaction =
                orderData.purchase_units[0].payments.captures[0];
              const { status } = transaction;

              if (status === "COMPLETED") {
                Services.get("order").then(async ([orderService]) => {
                  try {
                    this.getApp().sendToGA4Order(orderService.getData("order"));
                    await orderService.place({ transaction });
                    this.getPage().redirectToRedirect("/");
                    this.getComponents()
                      .findById("main-message")
                      .first()
                      .setData({
                        "message-duration": 5000,
                        "success-message": `${this.trans(
                          "complete-order-title"
                        )} ${this.trans("complete-order-subtitle")}`,
                      });
                  } catch (e) {
                    alert(e.getMessage());
                  }
                });
              }
            });
          },
        })
        .render("#paypal-button-container");
    }
  }

  addPayPalScript() {
    return new Promise((resolve, reject) => {
      const id = "pay-pal-sdk";

      if (!document.getElementById(id)) {
        let script = document.createElement("script");
        const paypalClientId =
          this.getHelpers("value").getValue("paypal-client-id");
        const paypalCurrency = this.getHelpers("value").getValue(
          "paypal-client-currency"
        );

        script.setAttribute(
          "src",
          `https://www.paypal.com/sdk/js?client-id=${paypalClientId}&currency=${paypalCurrency}&components=buttons&disable-funding=bancontact`
        );
        script.id = id;
        script.async = true;
        script.onload = () => {
          resolve();
        };
        script.onerror = () => {
          reject();
        };

        document.body.appendChild(script);
      } else {
        resolve();
      }
    });
  }

  isPaypal(order) {
    return order && order.paymentType.slug === "paypal";
  }
}
